.footer {
  margin: auto;
  background-color: #FFFFFF;
}
.compony-info {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.icp-info {
  margin-top: 8px;
  margin-bottom: 83px;
}