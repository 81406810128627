.registerBg {

}

.registerTittle {
  height: 70px;
  color: #343434;
  font-size: 38px;
  font-weight: bold;
  margin-top: 50px;
  margin-left: 80px;
}

.registerInputView {
  width: 1170px;
  margin-top: 15px;
  margin-left: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.registerInputTitle {
  width: 100px;
  margin-left: 30px;
  font-size: 16px;
  text-align: right;
  font: bold;
}

.registerInput {
  flex: 1;
  height: 30px;
  margin-left: 20px;
  border: solid 1px #d3d3d3; 
}

.checkBoxView {
  cursor: pointer;
  width: 1170px;
  margin-top: 30px;
  margin-left: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox {
  margin-left: 30px;
}

.checkboxLabel {
  margin-left: 20px;
  font-size: 16px;
}

.registerBtn {
  margin-top: 20px;
  margin-left: 230px;
  height: 40px;
  width: 140px;
  background-color: red;
  color: white;
  font-size: 16px;
  outline: none;
  border: 0;
}

.tipsLabel{
  display: none;
  color: red;
  font-size: 16px;
  margin-left: 300px;
}

.mask {
  display: none;
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  background-color: #000;
  opacity: 0.6;
}

.modal {
  position: fixed;
  display: none;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 1100px;
  overflow: auto;
}

.modal-body {
  padding: 20px;
  background-color: #FFFFFF;
  height: 800px;
  overflow: hidden;
  overflow-y: auto;
}

.closeModal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.closeButton {
  background-color: red;
  width: 100px;
  height: 40px;
  margin-right: 10px;
  color: #FFFFFF;
  outline: none;
  border: 0;
}

.userAgreement {
  cursor: pointer;
  color: #e84c3d;
  text-decoration: underline;
}