.loginBg {
  width: 1170px;
  margin: 0 auto;
}

.loginTitle {
  height: 70px;
  color: #343434;
  font-size: 38px;
  font-weight: bold;
  margin-top: 50px;
  margin-left: 80px;
}

.inputView {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-left: 100px;
}

.inputTitle {
  width: 100px;
  margin-left: 30px;
  font-size: 16px;
  text-align: right;
  font: bold;
  vertical-align: middle;
}

.inputBox {
  flex: 1;
  height: 40px;
  margin-left: 20px;
  border: solid 1px #d3d3d3; 
}

.inputPassword {
  width: 100px;
  margin-left: 30px;
  font-size: 16px;
  text-align: right;
  font: bold;
  vertical-align: middle;
}

.checkBoxView {
  width: 1170px;
  margin-top: 50px;
  margin-left: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox {
  margin-left: 30px;
}

.checkboxLabel {
  margin-left: 30px;
  font-size: 16px;
}

.loginBtn {
  margin-top: 30px;
  margin-left: 230px;
  height: 40px;
  width: 140px;
  background-color: red;
  color: white;
  font-size: 16px;
  outline:  none;
  border: 0;;
}

.registerLabel {
  color: red;
  font-size: 16px;
  margin-top: 50px;
  margin-left: 80px;
}

.tipsLabel{
  display: none;
  color: red;
  font-size: 16px;
  margin-left: 300px;
}
