.header {
  height: 70px;
  background-color: #fff;
}

.container {
  width: 1170px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.logo {
}

.logo img {
  width: 220px;
  vertical-align: middle;
}

.menus {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: end;
}

.menus .item {
  color: #666666;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 20px 12px 19px;
}

.menus .item a {
  text-decoration: none;
  color: #666666;
}