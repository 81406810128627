.bg {
  background-color: #FFFFFF;
}
.banner {
  width: 100%;
  
}

.banner img {
  width: 100%;
}

.listTop {
  width: 100%;
  margin-top: 19px;
  margin-bottom: 19px;
  color: #282828;
  letter-spacing: 0;
  font-size: 18px;
  text-align: center;
}

.listBg {
  width: 100%;
  height: auto;
}