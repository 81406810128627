.listView {
  display: flex;
  flex-direction: column;
  background-color: #F4F4F4;
}

.listRow {
  display: flex;
  margin: 10px 12px 19px 30px;
  flex-direction: row;
}

.listItem {
  flex: 1;
  margin-right: 18px;
  background-color: #FFFFFF;
}

.listItem img {
  width: 100%;
  /* height: 300px; */
}

.itemTitle {
  margin: 11px 25px 0 25px;
  color: #282828;
  font-size: 25px;
  letter-spacing: 0.04px;
  text-align: justify;
}

.itemBottom {
  margin-top: 30px;
  margin-bottom: 21px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itempPrice {
  margin-left: 25px;
  color: #DE2F3C;
  font-size: 25px;
  letter-spacing: 0.04px;
  text-align: center;
}

.bottomBtn {
  width: 96px;
  margin-right: 26px;
  height: 38px;
  color: #FFFFFF;
  font-size: 20px;
  background-color: #3FB830;
  border-radius: 3.84px;
  line-height: 38px;
  text-align: center;
}

.mask {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: #000;
  opacity: 0.6;
}

.qr_container .modal {
  position: fixed;
  display: none;
  top: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 500px;
  /* height: 600px; */
  
}

.qr_container .modal img {
  width: 100%;
}